<template>
    <div class="hero">
        <div class="container">
            <div class="hero-content">
                <div v-wow="{ 'animation-name': 'fadeInLeft','animation-duration': '2s' }" class="hero-text">
                    <h1 class="title">
                        What is the Founding<br>Creators Program?
                    </h1>
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <h1>Memo’d is a note-sharing app where you can find inspirations and ideas shared by our community of creators.</h1>
                            <button class="nav-link btn btn-primary mt-4" @click="handleFoundersInvitation">
                                Start now
                            </button>
                        </div>
                        <div class="col-12 col-lg-8" @click="$modal.show('video-intro-modal')">
                            <div v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '2s' }" class="video-preview">
                                <div class="play-button">
                                    <div class="row no-gutters justify-content-center align-items-center">
                                        <div class="col-auto">
                                            <div class="play-icon">
                                                <img src="@assets/img/founders-landing/hero/play-icon.svg" alt="Play">
                                            </div>
                                        </div>
                                        <div class="col">
                                            <span>Watch video</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hero",
    props: {
        isValidInvitation: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleFoundersInvitation() {
            this.isMobileMenuOpen = false;
            this.$modal.show(this.isValidInvitation ? "get-started-invited" : "get-started-no-invited");
        }
    }
}
</script>

<style lang="scss" scoped>
.hero {
    /* height: 100vh; */
    background-image: url("~@assets/img/founders-landing/hero/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 10vh;
    padding-bottom: 20vh;

    &::before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: -2px;
        left: 0;
        right: 0;
        opacity: 1;
        background: rgb(24,24,24);
        background: linear-gradient(0deg, rgba(24,24,24,1) 0%, rgba(0,0,0,0.8) 100%);
        animation-name: heroOpacity;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
    }

    .hero-content {
        position: relative;
        z-index: 2;
        display: flex;
        cursor: pointer;

        @media(max-width: $xl) {
            flex-direction: column;
        }

        .hero-text {
            position: relative;
            z-index: 4;

            .title {
                @extend .giant-title;
                color: $primary-color;
                text-align: center;
                margin-bottom: 8vh;
            }

            @media(max-width: $sm) {
                .title {
                    font-size: 40px;
                    line-height: 40px;
                }

                h1 {
                    font-size: 18px;
                    line-height: 18px;
                    text-align: center;
                    margin-bottom: 5vh;
                }
            }
        }

        .video-preview {
            border-radius: 12px;
            overflow: hidden;
            cursor: pointer;
            height: 400px;
            background-image: url("~@assets/img/founders-landing/video-preview.jpg");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width: $md) {
                height: 300px;
            }

            @media(max-width: $sm) {
                height: 200px;
            }

            .play-button {
                width: 165px;
                height: 64px;
                background-color: rgba(0,0,0,0.4);
                border-radius: 60px;
                display: flex;
                align-items: center;
                justify-content: center;

                .play-icon {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid rgba(235,235,245,0.6);
                    border-radius: 100%;
                    margin-right: 10px;

                    img {
                        margin-left: 4px;
                    }
                }
            }

        }
    }
}

@keyframes heroOpacity {
    from {
        opacity: 0.7;
    }

    to {
        opacity: 1;
    }
}
</style>
